/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { graphql } from 'gatsby'
import styled, { mediaQuery } from 'src/styles'
import { PageHeading, Heading } from 'src/components/molecules/Heading'
import { Inner } from 'src/components/layouts/Inner'
import { PageDescription } from 'src/components/domains/pages/PageDescription'
import { LinkButton, ButtonWrapper } from 'src/components/domains/app/Button'
import { FaqItem, FaqTitle } from 'src/components/domains/recruit/Faq'
import { StaffMessage } from 'src/components/domains/recruit/StaffMessage'
import { ElementContainer } from 'src/components/layouts/ElementContainer'
import { Flow } from 'src/components/molecules/Flow'
import { v4 as uuid } from 'uuid'
import { useSiteMetadata } from 'src/hooks'
import PageMeta from 'src/components/PageMeta'
import Layout from '../components/Layout'

type PageProps = {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string
        titleEn: string
        description: string
        descriptionSeo?: string
        interview: {
          avatar: any
          title: string
          message: string
          name: string
        }[]
        flow: {
          icon: any
          title: string
          description: string
        }[]
        faq: {
          heading: string
          faqList: {
            type: 'question' | 'answer'
            text: string
          }[]
        }[]
      }
    }
  }
}

export const RecruitPageTemplate: React.FC<PageProps['data']['markdownRemark']['frontmatter']> = (props) => {
  const { title, titleEn, description, interview, flow, faq } = props

  return (
    <>
      <ScPageHeading component="h2" heading={title} subHeading={titleEn} />

      <ElementContainer>
        <PageDescription>{description}</PageDescription>
      </ElementContainer>

      <ElementContainer>
        <LinkButton to="/recruit/entry" icon="checked">
          採用応募にエントリーする
        </LinkButton>
      </ElementContainer>

      <ElementContainer>
        <ScHeading>社員インタビュー</ScHeading>
        {!!interview && interview.map((v) => <StaffMessage interview={v} key={uuid()} />)}
      </ElementContainer>

      <ElementContainer>
        <ScHeading>応募からおしごと開始までの流れ</ScHeading>
        {!!flow && <Flow flow={flow} />}
      </ElementContainer>

      <ElementContainer>
        <ScHeading>よくある質問</ScHeading>
        {!!faq &&
          faq.map((v) => (
            <React.Fragment key={uuid()}>
              <FaqTitle>{v.heading}</FaqTitle>
              {v.faqList.map((f) => (
                <FaqItem variant={f.type} text={f.text} key={uuid()} />
              ))}
            </React.Fragment>
          ))}
      </ElementContainer>

      <ButtonWrapper align="center">
        <LinkButton to="/recruit/entry" icon="checked">
          採用応募にエントリーする
        </LinkButton>
      </ButtonWrapper>
    </>
  )
}

const RecruitPage: React.FC<PageProps> = ({ data }) => {
  const { siteTitle } = useSiteMetadata()
  const { title, titleEn, description, descriptionSeo, interview, flow, faq } = data.markdownRemark.frontmatter

  return (
    <Layout>
      <PageMeta title={`${title} | ${siteTitle}`} description={descriptionSeo} />
      <Inner>
        <RecruitPageTemplate
          title={title}
          titleEn={titleEn}
          description={description}
          interview={interview}
          flow={flow}
          faq={faq}
        />
      </Inner>
    </Layout>
  )
}

export default RecruitPage

// Style
const ScPageHeading = styled(PageHeading)`
  margin-bottom: 1.25rem;
  ${mediaQuery('sm')} {
    margin-bottom: 2.5rem;
  }
`

const ScHeading = styled(Heading)`
  margin-bottom: 1em;
`

// GraphQL
export const servicePageQuery = graphql`
  query RecruitPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        titleEn
        description
        descriptionSeo
        interview {
          avatar {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          message
          name
        }
        flow {
          icon {
            childImageSharp {
              fluid(maxWidth: 120, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          description
        }
        faq {
          heading
          faqList {
            type
            text
          }
        }
      }
    }
  }
`
