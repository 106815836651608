import React from 'react'
import styled, { mediaQuery } from 'src/styles'
import { ComponentStyleProps } from 'src/types'

type Props = {
  variant: 'question' | 'answer'
  text: string
} & ComponentStyleProps

// Faq Title
export const FaqTitle: React.FC<ComponentStyleProps> = (props) => {
  const { children } = props
  return <ScFaqTitle {...props}>{children}</ScFaqTitle>
}

const ScFaqTitle = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1.5em;
  margin-bottom: 1em;
  ${mediaQuery('sm')} {
    font-size: 1.5rem;
  }
`

// Faq Item
export const FaqItem: React.FC<Props> = (props) => {
  const { variant, text } = props
  return (
    <ScFaqItem variant={variant}>
      <ScIcon variant={variant} />
      <ScText>{text}</ScText>
    </ScFaqItem>
  )
}

type ScProps = {
  variant: Props['variant']
}

const ScFaqItem = styled.div<ScProps>`
  ${({ theme, variant }) =>
    !!variant &&
    `
      border-left: 4px solid ${variant === 'answer' ? theme.color.creaith.green : theme.color.creaith.red};
      background: ${variant === 'answer' ? '#fff' : theme.color.background.gray};
  `}
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 24px;
`

const ScIcon = styled.div<ScProps>`
  ${({ theme, variant }) =>
    !!variant &&
    `
    color: ${variant === 'answer' ? theme.color.creaith.green : theme.color.creaith.red};
    &::before {
      content: '${variant === 'answer' ? 'A' : 'Q'}';
    }
  `}
  display: block;
  font-size: 1.24rem;
  font-weight: bold;
  margin-right: 1.5rem;
  ${mediaQuery('sm')} {
    margin-right: 3rem;
    font-size: 2.5rem;
  }
`

const ScText = styled.div`
  flex: 1;
  font-size: 0.875rem;
  ${mediaQuery('sm')} {
    font-size: 1rem;
  }
`
